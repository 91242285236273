
            @import '@/../styles/shared/helpers/_functions.scss';
            @import '@/../styles/shared/helpers/_mixins.scss';
            @import '@/../styles/shared/helpers/_fonts.scss';
            @import '@/../styles/shared/helpers/_global-variables.scss';
        
[theme="light"] {
  // main colors
  --black:              hsl(0deg 0% 0%);
  --system:             hsl(225deg 9% 18%);
  --system_additional:  hsl(228deg 2% 51%);
  --unactive:           hsl(220deg 2% 67%);
  --border:             hsl(240deg 1% 84%);
  --bg:                 hsl(200deg 21% 95%);
  --divider:            hsl(200deg 21% 95%);
  --phantom:            hsl(0deg 0% 100%);
  --phantom_64:         hsla(0deg 0% 100% / 64%);
  --phantom_40:         hsla(0deg 0% 100% / 40%);
  --phantom_20:         hsla(0deg 0% 100% / 20%);
  --header:             var(--brand);
  --brand:              hsl(210deg 57% 26%);
  --brand_light:        hsl(210deg 57% 32%);
  --brand_accent:       hsl(210deg 57% 26%);
  --brand_64:           hsla(210deg 57% 26% / 64%);
  --brand_40:           hsla(210deg 57% 26% / 40%);
  --brand_12:           hsla(210deg 57% 26% / 12%);
  --shadow:             hsla(0deg 0% 0% / 16%);
  
  // additional colors
  --header_text:    var(--phantom);
  --header_text_64: var(--phantom_64);
  --header_text_40: var(--phantom_40);
  --header_text_20: var(--phantom_20);
  --header_light:   var(--brand_light);

  // accent colors
  --alert:            hsl(25deg 100% 52%);
  --alert_light:      hsl(25deg 100% 58%);
  --caution:          hsl(40deg 100% 51%);
  --caution_light:    hsl(40deg 100% 61%);
  --success:          hsl(137deg 93% 28%);
  --success_light:    hsl(137deg 63% 39%);
  --normal:           hsl(208deg 62% 40%);
  --normal_50:        hsla(208deg 62% 40% / 50%);
  --normal_light:     hsl(208deg 88% 59%);
  --normal_selected:  hsl(208deg 86% 92%);
  --normal_hover:     hsl(207deg 90% 96%);

  // button colors
  --btn_alert_default:    hsl(25deg 100% 58%);
  --btn_alert_hover:      hsl(25deg 100% 52%);
  --btn_alert_pressed:    hsl(25deg 100% 48%);
  --btn_success_default:  hsl(137deg 63% 39%);
  --btn_success_hover:    hsl(137deg 63% 36%);
  --btn_success_pressed:  hsl(137deg 63% 32%);
  --btn_normal_default:   hsl(208deg 62% 40%);
  --btn_normal_hover:     hsl(208deg 62% 32%);
  --btn_normal_pressed:   hsl(208deg 62% 28%);

  // backlight
  --backlight_alert:    hsl(25, 100%, 93%);
  --backlight_success:  hsl(138, 40%, 90%);
  --backlight_normal:   hsl(208, 88%, 93%);

  //gradients
  --gradient_full:  linear-gradient(180deg, #E1EDF5 0%, var(--phantom) 100%);
  --gradient_top:   linear-gradient(180deg, #E1EDF5 0%, var(--phantom) 16%);
  
  // error page icon
  --error_icon_color_1: hsl(0deg 0% 100%);
  --error_icon_color_2: hsl(0deg 100% 72%);
  --error_icon_color_3: hsl(226deg 100% 61%);
}

:root {
  // status colors
  --status_1_accepted:          hsl(178deg 100% 25%);
  --status_2_lost:              hsl(357deg 76% 49%);
  --status_3_won:               hsl(138deg 67% 30%);
  --status_4_paid:              hsl(205deg 100% 38%);
  --status_5_delete:            var(--black);
  --status_6_payment_expired:   hsl(334deg 69% 48%);
  --status_7_blocked:           hsl(264deg 97% 62%);
  --status_8_sold:              hsl(210deg 6% 44%);
}
