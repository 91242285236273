// Roboto
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
  url('../../../fonts/Roboto/Roboto-Light.woff2') format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"),
  url('../../../fonts/Roboto/Roboto-Regular.woff2') format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Medium"),
  url('../../../fonts/Roboto/Roboto-Medium.woff2') format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Bold"),
  url('../../../fonts/Roboto/Roboto-Bold.woff2') format("woff2");
  font-weight: 700;
}

// Roboto Condensed
@font-face {
  font-family: "Roboto Condensed";
  src: local("RobotoCondensed-Regular"),
  url('../../../fonts/RobotoCondensed/RobotoCondensed-Regular.woff2') format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto Condensed";
  src: local("RobotoCondensed-Bold"),
  url('../../../fonts/RobotoCondensed/RobotoCondensed-Bold.woff2') format("woff2");
  font-weight: 700;
}

// Roboto Mono
@font-face {
  font-family: "Roboto Mono";
  src: local("RobotoMono-Light"),
  url('../../../fonts/RobotoMono/RobotoMono-Light.woff2') format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto Mono";
  src: local("RobotoMono-Medium"),
  url('../../../fonts/RobotoMono/RobotoMono-Medium.woff2') format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto Mono";
  src: local("RobotoMono-Bold"),
  url('../../../fonts/RobotoMono/RobotoMono-Bold.woff2') format("woff2");
  font-weight: 700;
}

// Cairo, for rtl languages
@font-face {
  font-family: "Cairo";
  src: local("Cairo"),
  url('../../../fonts/Cairo/Cairo-Light.woff2') format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Cairo";
  src: local("Cairo-Regular"),
  url('../../../fonts/Cairo/Cairo-Regular.woff2') format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Cairo";
  src: local("Cairo-Medium"),
  url('../../../fonts/Cairo/Cairo-Medium.woff2') format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "Cairo";
  src: local("Cairo-Bold"),
  url('../../../fonts/Cairo/Cairo-Bold.woff2') format("woff2");
  font-weight: 700;
}

// HindSiliguri, for bu lang
@font-face {
  font-family: "HindSiliguri";
  src: local("HindSiliguri"),
  url('../../../fonts/HindSiliguri/HindSiliguri-Light.woff2') format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "HindSiliguri";
  src: local("HindSiliguri-Regular"),
  url('../../../fonts/HindSiliguri/HindSiliguri-Regular.woff2') format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "HindSiliguri";
  src: local("HindSiliguri-Medium"),
  url('../../../fonts/HindSiliguri/HindSiliguri-Medium.woff2') format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "HindSiliguri";
  src: local("HindSiliguri-Bold"),
  url('../../../fonts/HindSiliguri/HindSiliguri-Bold.woff2') format("woff2");
  font-weight: 700;
}