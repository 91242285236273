$fz: 16;
$fz_base: 16;
$font_Roboto: 'Roboto', 'Roboto Local', arial, sans-serif;
$font_Roboto_Mono: 'Roboto Mono', 'Roboto Mono Local', arial, sans-serif;
$font_Roboto_Condensed: 'Roboto Condensed', 'Roboto Condensed Local', arial, sans-serif;
$font_Cairo: 'Cairo', 'Cairo Local', arial, sans-serif;
$font_HindSiliguri: 'HindSiliguri', 'HindSiliguri Local', arial, sans-serif;
$focus: hsl(217deg 99% 65%); // #4d90fe

// Universal Breakpoint Media Query
$laptop_4k: 3840px;
$iMac_retina_5k: 2560px;
$iMac_retina_4k: 2048px;
$iMac_fullHD: 1920px;
$macBook_pro_15inch: 1680px;
$laptop: 1600px;
$macBook_pro_16inch: 1536px;
$macBook_pro_old: 1440px;
$old_laptops: 1367px;
$macBook_pro_13inch: 1280px;
$macBook_12inch: 1152px;
$tablet: 1023px;

:root {
  //other global
  --header-height_desktop: #{em(64, $fz)};
  --header-height_mobile: #{em(48, $fz)};
}